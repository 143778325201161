import servicers from './servicer'
function generateOrder(n, status = 0) {
  let tableData = []
  let users = ['jz', 'yz', 'shtolerance']
  let servicers = ['上海芮宸', '上海睿现', '托能斯', '上海极臻']
  let folders = ['小黄人30g', '小黄人180g', '钢铁侠头盔v2.4.2', '钢铁侠170g', '哪吒v1.0', '哪吒150g', '十二生肖v3.2.1', '十二生肖15cm', '底座3.1']


  for (let i = 0; i < n; i++) {
    tableData.push({
      orderId: "20211946" +
        (parseInt(Math.random() * 10000) + 10000).toString().slice(1, 5),
      status,
      user: users[parseInt(Math.random() * 100) % users.length],
      model: folders[parseInt(Math.random() * 100) % folders.length],
      folder: folders[parseInt(Math.random() * 100) % folders.length],
      quantity: (parseInt(Math.random() * 100) % 8) * 10,
      material: "树脂",
      forming: "SLA",
      tech: 0,
      servicer: servicers[parseInt(Math.random() * 100) % servicers.length],
      date: `2021-0${(parseInt(Math.random() * 100) % 8) + 1}-${(parseInt(Math.random() * 1000) % 17) + 10}`,
      time: `${(parseInt(Math.random() * 100) % 10) + 10}:${(parseInt(Math.random() * 100) % 50) + 10}:${(parseInt(Math.random() * 100) % 50) + 10}`,
      reworkTime: (parseInt(Math.random() * 100) % 4),
      note: "",
      createDate: `2021-0${(parseInt(Math.random() * 100) % 8) + 1}-${(parseInt(Math.random() * 1000) % 17) + 10}`,
    })
  }
  return tableData
}

function generateServicer() {
  return servicers.map(s => {
    return {
      name: s.name,
      password: "prism123",
      company: s.name,
      legal: '穆国虔',
      adddress: "上海",
      yard: s.yard,
      code: '91310115342211086E',
      scale: "50",
      tel: s.tel,
      status: "已通过",
    }
  })
}

export default {
  generateOrder,
  generateServicer
}