<template>
  <div>
    <div class="a-breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>首页</el-breadcrumb-item>
        <el-breadcrumb-item>服务商列表</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-table class="a-table" :data="tableData" @row-click="handleDetailShow">
      <el-table-column label="用户名" prop="userName" />
      <el-table-column label="展示名" prop="displayName" />
      <el-table-column label="公司名" prop="companyName" />
      <el-table-column label="法定代表人" prop="legalPerson" />
      <el-table-column label="生产场地" prop="productionSite" />
      <el-table-column label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isApproved" type="success" size="medium"
            >已通过</el-tag
          >
          <el-tag v-else size="medium">未通过</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="a-pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :layout="layout"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 服务商详情 -->
    <el-dialog
      :before-close="handleDetailClose"
      :visible.sync="visible.detail"
      title="服务商详情"
      width="480px"
      :destroy-on-close="true"
    >
      <el-row class="a-detail-item"
        ><el-col :span="8">用户名：</el-col
        ><el-col :span="16">{{ servicerInfo.userName }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">展示名：</el-col
        ><el-col :span="16">{{ servicerInfo.displayName }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">公司名：</el-col
        ><el-col :span="16">{{ servicerInfo.companyName }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">法定代表人：</el-col
        ><el-col :span="16">{{ servicerInfo.legalPerson }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">信用代码：</el-col
        ><el-col :span="16">{{ servicerInfo.creditCode }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">注册地址：</el-col
        ><el-col :span="16">{{
          servicerInfo.registeredAddress
        }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">生产规模：</el-col
        ><el-col :span="16">{{ servicerInfo.productionScale }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">生产场地：</el-col
        ><el-col :span="16">{{ servicerInfo.productionSite }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">联系方式：</el-col
        ><el-col :span="16">{{ servicerInfo.contact }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">状态：</el-col
        ><el-col :span="16">
          <template>
            <el-tag v-if="servicerInfo.isApproved" type="success" size="medium"
              >已通过</el-tag
            >
            <el-tag v-else size="medium">未通过</el-tag>
          </template></el-col
        ></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">创建时间：</el-col
        ><el-col :span="16">{{ servicerInfo.createTime }}</el-col></el-row
      >
      <el-row class="a-detail-item"
        ><el-col :span="8">更新时间：</el-col
        ><el-col :span="16">{{ servicerInfo.updateTime }}</el-col></el-row
      >
      <div class="a-detail-operate">
        <el-button @click="handleAccept" type="primary">通过</el-button>
        <el-button @click="handleDisable" type="danger">禁用</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import utils from "../utils";
export default {
  data() {
    return {
      tableData: utils.generateServicer(),
      visible: {
        detail: false,
      },
      servicerInfo: {},
      /**分页 */
      pageInfo: {
        pageSizes: [20, 50, 100],
        pageSize: 20,
        layout: "total, sizes, prev, pager, next, jumper",
        currentPage: 1,
        total: 1,
      },
      loading: true,
    };
  },
  methods: {
    getUserList() {
      this.loading = true;
      const page = {
        pageIndex: this.pageInfo.currentPage,
        pageSize: this.pageInfo.pageSize,
      };
      this.$axios.getServiceList({ ...page }).then((res) => {
        if (!res.error) {
          this.pageInfo.total = res.totalCount;
          this.tableData = res.data;
        } else {
          this.$notify.error({
            title: "提示",
            message: res.error,
            duration: 2000,
          });
        }
        this.loading = false;
      });
    },
    /**服务商详情 */
    handleAccept() {
      this.$axios
        .approvalServicer({ username: this.servicerInfo.userName })
        .then((res) => {
          if (!res.error) {
            this.$message({
              message: "用户通过成功",
              type: "success",
            });
            this.getUserList();
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
        });
      this.handleDetailClose();
    },
    handleDisable() {
      this.$axios
        .disableServicer({ username: this.servicerInfo.userName })
        .then((res) => {
          if (!res.error) {
            this.$message({
              message: "用户禁用成功",
              type: "success",
            });
            this.getUserList();
          } else {
            this.$notify.error({
              title: "提示",
              message: res.error,
              duration: 2000,
            });
          }
        });
      this.handleDetailClose();
    },
    handleDetailShow(row) {
      event.preventDefault();
      // console.log(row, col, e);
      this.servicerInfo = row;
      this.visible.detail = true;
    },
    handleDetailClose() {
      this.servicerInfo = "";
      this.visible.detail = false;
    },
    /**分页 */
    // 页面尺寸改变
    handleSizeChange(val) {
      this.pageInfo.pageSize = val;
      this.getUserList();
    },
    // 当前页改变
    handleCurrentChange(val) {
      this.pageInfo.currentPage = val;
      this.getUserList();
    },
  },
  mounted() {
    this.getUserList();
  },
};
</script>


<style lang="scss" scoped>
.a-detail-item {
  margin: 0.5rem 0;
}
.a-detail-operate {
  margin-top: 2rem;
  text-align: center;
}
</style>
