export default [
  {
    name: "保定星灿",
    tel: "13131229972",
    yard: "保定"
  },
  {
    name: "北京和胜",
    tel: "13801151256",
    yard: "北京"
  },
  {
    name: "北京京品",
    tel: "13911730892",
    yard: "北京"
  },
  {
    name: "北京天拓",
    tel: "13381089243",
    yard: "北京"
  },
  {
    name: "贝烈特",
    tel: "18521757759",
    yard: "上海"
  },
  {
    name: "成都登特",
    tel: "13208174171",
    yard: "成都"
  },
  {
    name: "成都金典",
    tel: "13980422121",
    yard: "成都"
  },
  {
    name: "成都美尔美",
    tel: "18381090977",
    yard: "成都"
  },
  {
    name: "成都顺雅",
    tel: "17364823867",
    yard: "成都"
  },
  {
    name: "成都宜隆",
    tel: "13888374808",
    yard: "四川"
  },
  {
    name: "大连奥利康",
    tel: "15940954589",
    yard: "大连"
  },
  {
    name: "大连鼎元",
    tel: "15641169188",
    yard: "大连"
  },
  {
    name: "大连盛源",
    tel: "18698860682",
    yard: "大连"
  },
  {
    name: "大连鑫成",
    tel: "15542442084",
    yard: "大连"
  },
  {
    name: "大连呀哆美",
    tel: "13029465855",
    yard: "大连"
  },
  {
    name: "大连玥明",
    tel: "15040544511",
    yard: "大连"
  },
  {
    name: "大连致美",
    tel: "13624098962",
    yard: "大连"
  },
  {
    name: "东莞定远",
    tel: "13686146840",
    yard: "东莞"
  },
  {
    name: "东莞钟天乐",
    tel: "13555660608",
    yard: "东莞"
  },
  {
    name: "佛山臻德",
    tel: "18520909628",
    yard: "佛山"
  },
  {
    name: "甘肃东兴",
    tel: "18194218526",
    yard: "甘肃"
  },
  {
    name: "广东精鹰",
    tel: "18818333070",
    yard: "广东"
  },
  {
    name: "广东汕尾雅冠",
    tel: "13729538100",
    yard: "汕尾"
  },
  {
    name: "广东职业",
    tel: "15989796178",
    yard: "广东"
  },
  {
    name: "广西百合",
    tel: "13500801155",
    yard: "广西"
  },
  {
    name: "贵州永美健",
    tel: "13984061168",
    yard: "贵州"
  },
  {
    name: "哈尔滨冠美",
    tel: "15220999777",
    yard: "哈尔滨"
  },
  {
    name: "哈尔滨美美达",
    tel: "18746026688",
    yard: "哈尔滨"
  },
  {
    name: "哈尔滨先东",
    tel: "13115553089",
    yard: "哈尔滨"
  },
  {
    name: "哈尔滨现代雅皓",
    tel: "18545510777",
    yard: "哈尔滨"
  },
  {
    name: "杭州佳杰",
    tel: "13505713875",
    yard: "杭州"
  },
  {
    name: "杭州开泰",
    tel: "13967603164",
    yard: "杭州"
  },
  {
    name: "杭州雅钰",
    tel: "13758213342",
    yard: "杭州"
  },
  {
    name: "杭州紫丁香",
    tel: "13588369729",
    yard: "杭州"
  },
  {
    name: "河南卡瓦",
    tel: "13783663142",
    yard: "郑州"
  },
  {
    name: "河南欧标",
    tel: "19937872777",
    yard: "河南"
  },
  {
    name: "河南濮阳",
    tel: "13525267166",
    yard: "濮阳"
  },
  {
    name: "黑龙江鼎元",
    tel: "13074575351",
    yard: "黑龙江"
  },
  {
    name: "黑龙江佳锋",
    tel: "18686862294",
    yard: "黑龙江"
  },
  {
    name: "湖北美院",
    tel: "13407191110",
    yard: "湖北"
  },
  {
    name: "湖南顺为",
    tel: "13424255306",
    yard: "湖南"
  },
  {
    name: "湖南蔚莱",
    tel: "15388000711",
    yard: "湖南"
  },
  {
    name: "惠州鲲鹏",
    tel: "15360990686",
    yard: "惠州"
  },
  {
    name: "吉林鼎元",
    tel: "17386896586",
    yard: "吉林"
  },
  {
    name: "吉林吉美",
    tel: "13196229632",
    yard: "吉林"
  },
  {
    name: "济南皓美",
    tel: "15806419996",
    yard: "济南"
  },
  {
    name: "济南美佳",
    tel: "13306408915",
    yard: "山东"
  },
  {
    name: "济南致美",
    tel: "18615196622",
    yard: "济南"
  },
  {
    name: "济宁天艺",
    tel: "18266892921",
    yard: "山东"
  },
  {
    name: "江西上饶",
    tel: "18520673367",
    yard: "江西"
  },
  {
    name: "昆明定美",
    tel: "18087826781",
    yard: "昆明"
  },
  {
    name: "昆明汇合",
    tel: "13888862899",
    yard: "昆明"
  },
  {
    name: "临汾尧都铭涵",
    tel: "13834876607",
    yard: "临汾"
  },
  {
    name: "牡丹江公元",
    tel: "16555006222",
    yard: "黑龙江"
  },
  {
    name: "南京佳和",
    tel: "13584073384",
    yard: "南京"
  },
  {
    name: "南京雅美",
    tel: "13851829607",
    yard: "南京"
  },
  {
    name: "南宁晶钻",
    tel: "15277161538",
    yard: "南宁"
  },
  {
    name: "平湖粤诚",
    tel: "13586436463",
    yard: "上海"
  },
  {
    name: "青岛华新华",
    tel: "18953275327",
    yard: "青岛"
  },
  {
    name: "青岛威尔赫",
    tel: "18669792666",
    yard: "青岛"
  },
  {
    name: "山东倍特美",
    tel: "18363755789",
    yard: "山东"
  },
  {
    name: "山东聚晟",
    tel: "18363354335",
    yard: "山东"
  },
  {
    name: "山东蓝合",
    tel: "13606243939",
    yard: "山东"
  },
  {
    name: "山西美原",
    tel: "13834870312",
    yard: "山西"
  },
  {
    name: "山西唯美",
    tel: "15903411571",
    yard: "山西"
  },
  {
    name: "山西卓越",
    tel: "13994316420",
    yard: "山西"
  },
  {
    name: "陕西精益",
    tel: "18091722666",
    yard: "陕西"
  },
  {
    name: "陕西前进",
    tel: "18009295789",
    yard: "陕西"
  },
  {
    name: "上海陈信",
    tel: "13701674950",
    yard: "上海"
  },
  {
    name: "上海杰达",
    tel: "13817626776",
    yard: "上海"
  },
  {
    name: "上海民晟",
    tel: "13761920975",
    yard: "上海"
  },
  {
    name: "上海齐骥",
    tel: "13501727124",
    yard: "上海"
  },
  {
    name: "上海三岛",
    tel: "13901902872",
    yard: "上海"
  },
  {
    name: "上海卓舰",
    tel: "18101645668",
    yard: "上海"
  },
  {
    name: "韶关新博美",
    tel: "13509853569",
    yard: "韶关"
  },
  {
    name: "深圳佰顿",
    tel: "13729516423",
    yard: "深圳"
  },
  {
    name: "深圳宝鑫",
    tel: "13923433576",
    yard: "深圳"
  },
  {
    name: "深圳国友",
    tel: "13826551863",
    yard: "深圳"
  },
  {
    name: "深圳久久",
    tel: "13714730230",
    yard: "深圳"
  },
  {
    name: "深圳康泰健美",
    tel: "13480605164",
    yard: "深圳"
  },
  {
    name: "深圳坤立",
    tel: "13316576708",
    yard: "深圳"
  },
  {
    name: "深圳岚世纪",
    tel: "15817358126",
    yard: "深圳"
  },
  {
    name: "深圳三专",
    tel: "13691896227",
    yard: "深圳"
  },
  {
    name: "深圳双成",
    tel: "18676920656",
    yard: "深圳"
  },
  {
    name: "深圳唯特美",
    tel: "13389186151",
    yard: "深圳"
  },
  {
    name: "深圳中南",
    tel: "15999585670",
    yard: "深圳"
  },
  {
    name: "沈阳固德",
    tel: "18309871963",
    yard: "沈阳"
  },
  {
    name: "沈阳和睦佳",
    tel: "13022494733",
    yard: "沈阳"
  },
  {
    name: "沈阳宏仁",
    tel: "13898863528",
    yard: "沈阳"
  },
  {
    name: "石家庄昌鸿",
    tel: "13833177650",
    yard: "石家庄"
  },
  {
    name: "苏州精瓷",
    tel: "13816853024",
    yard: "江苏"
  },
  {
    name: "泰安岳冠",
    tel: "15588536687",
    yard: "泰安"
  },
  {
    name: "泰州市奥美",
    tel: "13775686651",
    yard: "泰州"
  },
  {
    name: "唐山慧鑫",
    tel: "15130528818",
    yard: "唐山"
  },
  {
    name: "天津电影",
    tel: "13821000106",
    yard: "天津"
  },
  {
    name: "天津康齿乐",
    tel: "13366552201",
    yard: "天津"
  },
  {
    name: "天津优然美",
    tel: "13803087784",
    yard: "天津"
  },
  {
    name: "桐乡雅美",
    tel: "13819056372",
    yard: "桐乡"
  },
  {
    name: "潍坊嘉德隆",
    tel: "15165440391",
    yard: "山东"
  },
  {
    name: "无锡鼎元",
    tel: "13331114543",
    yard: "无锡"
  },
  {
    name: "无锡雅冠",
    tel: "18176657475",
    yard: "无锡"
  },
  {
    name: "西安华冠",
    tel: "13519184008",
    yard: "西安"
  },
  {
    name: "西安欧亚",
    tel: "18629040919",
    yard: "陕西"
  },
  {
    name: "银川东兴",
    tel: "18595140318",
    yard: "银川"
  },
  {
    name: "云南家红",
    tel: "18787062293",
    yard: "云南"
  },
  {
    name: "云南日冠",
    tel: "13888450273",
    yard: "云南"
  },
  {
    name: "运城圣冠",
    tel: "15513592062",
    yard: "运城"
  },
  {
    name: "运城圣华",
    tel: "18636388866",
    yard: "运城"
  },
  {
    name: "长春鼎元",
    tel: "15044054555",
    yard: "吉林"
  },
  {
    name: "长春聚力",
    tel: "18543140781",
    yard: "长春"
  },
  {
    name: "浙江宁波菲曼物联",
    tel: "13805860026",
    yard: "浙江"
  },
  {
    name: "郑州德派",
    tel: "13803866156",
    yard: "郑州"
  },
  {
    name: "重庆晶美",
    tel: "15923208032",
    yard: "重庆"
  },
  {
    name: "重庆态美",
    tel: "13527427837",
    yard: "重庆"
  },
  {
    name: "重庆维泰",
    tel: "18581318778",
    yard: "重庆"
  },
  {
    name: "珠海艾圻",
    tel: "13825678313",
    yard: "珠海"
  },
  {
    name: "珠海登赛",
    tel: "18933212806",
    yard: "珠海"
  },
  {
    name: "珠海美尔晶",
    tel: "7566253828",
    yard: "珠海"
  },
  {
    name: "珠海名匠",
    tel: "15916344948",
    yard: "珠海"
  },
  {
    name: "珠海璞瑧",
    tel: "13431933032",
    yard: "珠海"
  },
  {
    name: "珠海思康",
    tel: "13823018401",
    yard: "珠海"
  },
  {
    name: "珠海新茂",
    tel: "13570683963",
    yard: "珠海"
  },
  {
    name: "珠海优里卡",
    tel: "13007887581",
    yard: "珠海"
  }
]